import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Block from './Block'
import CustomerLogos from './CustomerLogos'
import Layout from './Layout'
import YoutubeVideo from './YoutubeVideo'

interface Props {
  header: ReactNode
  campaign: { value: string }
  formId: string
}

const WebinarPage = ({ header }: Props) => {
  return (
    <Layout justifyCenter title="7 ways to go wrong with engineering metrics">
      {header}
      <Block type="padding-top-medium">
        <Container>
          <div className="columns">
            <div className="col">
              <Intro />
            </div>
            <div className="col">
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  paddingBottom: '56.25%',
                  marginTop: '24px',
                  height: '0',
                }}
              >
                <YoutubeVideo videoId="FmLgd4rc81E" />
              </div>
            </div>
          </div>
        </Container>
      </Block>
      <FeaturedBlogArticles
        heading="More from Swarmia"
        articles={[
          '/blog/measuring-software-development-productivity/',
          '/blog/dora-metrics/',
          '/blog/git-analytics/',
        ]}
      />
      <Block type="padding-bottom-medium padding-top-none">
        <CustomerLogos />
      </Block>
    </Layout>
  )
}

const Intro = () => {
  return (
    <IntroWrapper>
      <h2
        style={{
          marginTop: '1.5rem',
        }}
      >
        Watch a recording of the live session
      </h2>
      <div className="text-tight">
        <p>
          The software industry has a long history in using harmful metrics to
          measure developer productivity.
        </p>
        <p>
          And that&#39;s why in this session, Swarmia CEO and founder, Otto
          Hilska, walks through seven ways companies get engineering metrics
          wrong — and four tips for getting started with healthy metrics that
          drive positive outcomes.
        </p>
      </div>
    </IntroWrapper>
  )
}

const IntroWrapper = styled.div`
  max-width: 640px;
  margin: 0 auto;
`

const Container = styled.div`
  .columns {
    display: flex;
    width: 100%;
    margin: auto;
    max-width: 1280px;
  }
  .col {
    padding: 0 32px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  margin-bottom: 12px;

  & h3 {
    opacity: 0.5;
    font-weight: 500;
  }

  // Show only one column if mobile
  @media screen and (max-width: 981px) {
    .columns {
      flex-direction: column;
      .col {
        width: 100%;
      }
    }
  }
`

export default WebinarPage
