import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import WebinarPage from '../../components/7WaysWebinarPage'

const SwarmiaWebinarPage = () => {
  return (
    <WebinarPage
      header={
        <div>
          <StaticImage
            src="../../assets/images/webinars/webinar-hero-on-demand.png"
            style={{
              width: '100%',
            }}
            quality={90}
            alt="Swarmia webinar"
            placeholder="blurred"
          />
        </div>
      }
      campaign={{
        value: 'Webinars',
      }}
      formId="438a0fad-2842-4d07-8452-d1f8a6487f4d"
    />
  )
}

export default SwarmiaWebinarPage
